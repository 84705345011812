import React from 'react'
import { Link } from 'gatsby'
import { removeAll } from '../services/cart'
import BooksInCartComplete from '../components/booksInCartComplete'
import Seo from "../components/seo"
import { sessionKeys } from "../services/const"
import { removeFormValues } from '../services/form'

class OrderComplete extends React.Component {

  componentDidMount = () => {
    removeAll()
    removeFormValues(sessionKeys.orderForm);
  }

  render = () => {
    return (
      <>
        <Seo title="出版・刊行物 ご注文手続き" />
        <div id="pageTitle">
          <h1>
            出版・刊行物 ご注文手続き
          </h1>
        </div>
        <div className="breadClumb">
          <ul>
            <li><Link to="/">HOME</Link></li>
            <li><Link to="/publication">出版・刊行物</Link></li>
            <li>出版・刊行物 ご注文手続き</li>
          </ul>
        </div>

        <div id="main">
          <div className="contents">
            <BooksInCartComplete></BooksInCartComplete>
          </div>
        </div>
      </>
    )
  }
}

export default OrderComplete