const isBrowser = () => typeof window !== "undefined"

export const getCart = () => {
  return isBrowser() && window.sessionStorage.getItem("cart")
    ? JSON.parse(window.sessionStorage.getItem("cart"))
    : []
}

export const addCart = (book) => {
  const items = getCart()
  if (items.filter(element => element.id === book.id).length > 0) {
    items.forEach(element => {
      if (element.id === book.id) {
        element.count++
      }
    })
  } else {
    items.push(book);
  }
  window.sessionStorage.setItem("cart", JSON.stringify(items))
}

export const setItemCount = (itemId, itemCount) => {
  const items = getCart()
  if (items.filter(element => element.id === itemId).length > 0) {
    items.forEach(element => {
      if (element.id === itemId) {
        element.count = itemCount
      }
    })
  }
  window.sessionStorage.setItem("cart", JSON.stringify(items))
}

export const removeCart = (id, isReload = false) => {
  const old = getCart();
  window.sessionStorage.setItem("cart", JSON.stringify(old.filter(book => book.id !== id)))
  if (isReload) {
    window.location.reload()
  }
}

export const removeAll = () => {
  window.sessionStorage.setItem("cart", [])
}