import { post } from "./api"

export const isBrowser = () => typeof window !== "undefined"

export const getUser = () =>
  isBrowser() && window.sessionStorage.getItem("gatsbyUser")
    ? JSON.parse(window.sessionStorage.getItem("gatsbyUser"))
    : {}

const setUser = user =>
  window.sessionStorage.setItem("gatsbyUser", JSON.stringify(user))

export const login = async ({ MemberNo, Password }) => {
  const postData = {
    MemberNo: MemberNo,
    Password: Password,
  };

  return post(process.env.API_GATEWAY_BASE_URL + "login", postData, () => {
    setUser({
      MemberNo: MemberNo
    })
  })
}

export const isLoggedIn = () => {
  const user = getUser()
  return !!user.MemberNo
}

export const logout = callback => {
  setUser({})
  window.sessionStorage.setItem("cart", {})
  callback()
}

export const getJwt = async (userId, callback) => {
  const postData = {
    LoginId: userId
  };
  return post(process.env.API_GATEWAY_BASE_URL + "create_jwt", postData, callback)
}